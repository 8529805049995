<template>
  <div>
    <!-- 头部 -->
    <div class="header">
      <div class="header_info">
        <img class="header_img" src="../../assets/header.png" />
        <div style="height: 100%" class="header_user">
          <van-image round fit="cover" class="user_img" :src="userFrom.user_avatar" />
          <div class="user_info">
            <div class="userFromText">{{ userFrom.user_name }}</div>
            <div style="display: flex">
              <div class="vipImg1">
                <img style="width: 50px; height: 23px; margin: 5px 5px 0 0" src="../../assets/lador_leve.png" />
                <div class="vip_text1" v-if="userFrom.member">
                  v{{ userFrom.member.vip_level }}
                </div>
              </div>
              <!-- <img class="lador_icon" v-if="userFrom.union_member != 0" src="../../assets/ladoricon.png" alt=""> -->
              <!-- <img class="lador_icon" v-if="userFrom.union_shareholder != 0" src="../../assets/gufen.png" alt=""> -->
            </div>
          </div>
          <div class="info" @click="userGoPage(1)">个人资料></div>
        </div>
        <div class="userBtn" v-if="!userFrom.union_info" @click="userGoPage(2)">会员申请</div>
        <!-- 会员延期 -->
        <div class="text_info">
          <div class="status_info">
            <div>会员授权时间：{{ userFrom.union_expire_at }}</div>
            <div class="info_right" @click="userGoPage(3)">前往延期 ></div>
          </div>
          <div class="status_info" v-if="days < 31">
            <img src="../../assets/Vector@2x.png" />
            <div v-if="userFrom.renew.over == 0" class="info_left">
              会员身份还有{{ userFrom.renew.over }}天到期
            </div>
            <div v-else class="info_left">
              会员身份已逾期{{ userFrom.renew.over }}天，账号冻结请及时处理
            </div>
            <div @click="userGoPage(4)">去处理 ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 金刚区 -->
    <div class="lador_list">
      <div class="list_title">
        <div>
          <div style="font-size: 25px; color: #2e2e30">
            权益应用
            <span style="font-size: 10px">APPLICATION</span>
          </div>
          <div style="margin-top: 5px">贴心服务，助力美好生活</div>
        </div>
        <div class="phone_icon" @click="userGoPage(5)"><img src="../../assets/message.png" /> 联系客服</div>
      </div>
      <div class="listLog">
        <div v-for="(item, index) in iconList" :key="index">
          <div class="list_icon1">
            <div class="list_img1" @click="listBtn(index)">
              <img v-if="index == 0" src="../../assets/Group_39931@2x.png" />
              <img v-if="index == 1" src="../../assets/Group_39932@2x.png" />
              <img v-if="index == 2" src="../../assets/pay.png" />
              <img v-if="index == 3" src="../../assets/Slice42.png" />
              <img v-if="index == 4" src="../../assets/icon4.png" />
              <img v-if="index == 5" src="../../assets/Group_39936@2x.png" />
              <img v-if="index == 6" src="../../assets/icon6.png" />
              <img v-if="index == 7" src="../../assets/Group_39938@2x.png" />
              <img v-if="index == 8" src="../../assets/Group_39939@2x.png" />
              <img v-if="index == 9" src="../../assets/Slice10.png" />
            </div>
            <div style="margin-top: 10px">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 提示框 -->
    <toast v-if="toastShow" :config="configData"></toast>

    <!-- 弹窗提示 -->
    <dispose
      :userFrom="userFrom"
      @imgBtn="imgBtn"
      :loginShow="loginShow"
      v-if="disposeShow"
    ></dispose>

    <!-- 提示框 -->
    <van-dialog v-model="show" :show-confirm-button="false">
      <div class="cancel_box">
        <img src="https://cdn.health.healthplatform.xyz/Fjmn3KOW5Zax17HdrGyhlwktZQOw" />
        <div class="text_box">
          <img @click="cloneBtn" src="../../assets/cloneBtn2.png" />
          <div class="message_title">温馨提示</div>
          <div class="message_text">
            尊敬的会员您好，您的销售合同即将到期，逾期后将无法进行工分置换，请仔细阅读与了解续签协议，如果同意请确认，在您置换过程中会同步完成续签，原有销售合同将失效。
          </div>
          <div class="sure_btn" @click="sureBtn">我知道了</div>
          <div class="text1" @click="sureBtnUser">
            单独续签
          </div>
          <div class="rule_text">
            《企业销售续签协议》
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { getCode_api, getuser_api } from "@/api/common";
import Cookies from "js-cookie";
import toast from "@/components/toast";
import { Dialog } from 'vant';
import dispose from "@/components/goods/dispose";
export default {
  data() {
    return {
      iconList: [
        { icon: "../../assets/icon2.png", text: "我的会员" },
        { icon: "../../assets/icon3.png", text: "电子证件" },
        { icon: "../../assets/icon3.png", text: "工分申请" },
        { icon: "../../assets/Slice42.png", text: "地址管理" },
        { icon: "../../assets/user.png", text: "电子合同" },
        { icon: "../../assets/pay.png", text: "工分明细" },
        { icon: "../../assets/shouyi.png", text: "物料中心" },
        { icon: "../../assets/duihuan.png", text: "物料变更" },
        { icon: "../../assets/Slice10.png", text: "服务站点" },
        { icon: "../../assets/shouyi.png", text: "工分增值" },
      ],
      userFrom: {
        renew: {
          audit: {},
        },
        shareholder_renew: {
          audit: {},
        },
      },
      configData: {
        title: "",
      },
      toastShow: false,
      disposeShow: false,
      loginShow: true,
      days: 0,
      show:false
    };
  },
  components: {
    toast,
    dispose,
  },
  created() {
    console.log(this.$router.query, 11111111111111111);
    document.title = "个人中心";
    // this.getCode();
    this.getUser();
  },
  methods: {
    userGoPage(val){
      if(val == 1) {
        this.$router.push("../personalData/mine"); //个人资料
      }else if (val == 2){
        this.$router.push("../newLogin/application"); //会员申请
      }else if (val == 3){
        this.$router.push("../newLogin/exchangeTime"); //会员延期
      }else if (val == 4){
        this.$router.push("../newLogin/exchangeTime"); //会员延期
      }else if (val == 5){
        this.$router.push("../lador/service");  //跳转客服
      }
    },
    imgBtn() {
      this.disposeShow = false;
    },
    //
    listBtn(index) {
      // 判断是否后台冻结
      if(this.userFrom.member.shares_freeze==1 || this.userFrom.union_member_freeze==1){
            this.$toast("账号已冻结,请联系管理员")
            return false
      }

      if (this.userFrom.union_member == 0 && index != 0) {
        this.disposeShow = true;
        return false;
      }
      switch (index) {
        case 0:
          if(!this.userFrom.union_info){
            this.$router.push("../newLogin/application"); //未成为会员
          }else{
            this.$router.push('../newLogin/applicationSuccess') //会员兑换时长
          }
          break;
        case 1:
          this.$router.push("../document/documentForm"); // 电子证件
          break;
        case 2:
          // 判断是否逾期需要续费
          if(this.userFrom.overdue==1){
            this.show = true;
            return false
          }
          this.$router.push("../workpoints/replacement");

          break;
        case 3:
          this.$router.push("../address");
          break;
        case 11:
          this.$router.push("../deal/return");
          break;
        case 4:
          if (this.userFrom.union_shareholder == 0) {
            this.toastShow = true;
            this.configData.title = "您还不是股东，请先申请会员";
            setTimeout(() => {
              this.toastShow = false;
            }, 1000);
            return false;
          }
          this.$router.push("../electronicContract/index");
          break;
        case 6:
          this.$router.push("../alteration/applicationRecord");
          break;
        case 5:
        if (this.userFrom.union_shareholder == 0){
            Dialog.confirm({
              title: "当前不是工会会员点击确认去'工分申请'",
              message: "",
            })
              .then(() => {
                this.$router.push("../workpoints/replacement");
              })
              .catch(() => {
                console.log(1)
                // on cancel
              });
          }
          this.$router.push("../deal/message");
          break;
        case 8:
          this.$router.push("../personalData/serveSite"); // 服务站点
          break;
        case 7:
          if (this.userFrom.union_shareholder == 0) {
            this.$toast("当前暂无操作权限");
            return false;
          }
          this.$router.push("../alteration/index");
          break;
        case 9:
          this.$router.push("../conversion/index"); // 服务站点
          break;
        default:
          break;
      }
    },

    // 单独续签
    sureBtnUser(){
      this.$router.push("../workpoints/enterprise?overdue=1")
    },
    // 工分申请,续签
    sureBtn(){
      this.$router.push("../workpoints/replacement")
    },
    // 股东续费
    renewBtn() {
      console.log(89);
      this.$router.push("../workpoints/renew");
    },
    confimBtn() {
      this.$router.push(
        `../expenditure/vipUser/contract?type=1&id=${this.userFrom.renew.audit.audit_id}`
      );
    },

    confimBtn1() {
      this.$router.push(
        `../expenditure/vipUser/contract?id=${this.userFrom.shareholder_renew.audit.audit_id}`
      );
    },
    // getCode() {
    //   //let redirect_uri = encodeURIComponent(location.href);
    //   let redirect_uri = encodeURIComponent(
    //     // "http://localhost:8080/lador/#/"
    //     " https://trade.healthplatform.xyz/#/"
    //   );
    //   let url = window.location.href;
    //   if (url.indexOf("code") == -1) {
    //     window.location.href =
    //       "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx70deea58f0c566b2&redirect_uri=" +
    //       redirect_uri +
    //       "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    //   }
    //   let codeUrl = JSON.parse(this.queryString(url));
    //   let code = codeUrl.code;
    //   console.log(code);
    //   this.getUserInfo(code);
    // },
    //获取用户信息
    async getUserInfo(code) {
      let data = {
        code: code,
      };
      getCode_api(data).then((res) => {
        if (res.headimgurl) {
          this.datum[0] = res;
          Cookies.set("token", res);
          // locache.set('milk_seller_info',res,604800);
        } else {
          // this.datum[0] = locache.get('milk_seller_info')
        }
      });
    },
    getUser() {
      getuser_api().then((res) => {
        console.log(res, "res");
        if (res.code == 0) {
          this.userFrom = res.data;
          if (!this.userFrom.renew) {
            this.userFrom.renew = {};
          }
          if (!this.userFrom.renew) {
            this.userFrom.shareholder_renew = {};
          }
          if (this.userFrom.union_expire_at) {
            if (
              new Date().getTime() + 2592000000 >
              new Date(this.userFrom.union_expire_at).getTime()
            ) {
              this.userFrom.vipUser = 1;
            } else {
              this.userFrom.vipUser = 0;
            }
          } else {
            this.userFrom.vipUser = 3;
          }
          if (this.userFrom.union_shareholder_expire_at) {
            if (
              new Date().getTime() + 2592000000 >
              new Date(this.userFrom.union_shareholder_expire_at).getTime()
            ) {
              this.userFrom.vipShare = 1;
            } else {
              this.userFrom.vipShare = 0;
            }
          } else {
            this.userFrom.vipShare = 3;
          }


          // 判断工分是否逾期
          if (this.userFrom.union_shareholder_expire_at) {
            if (
              new Date().getTime() >
              new Date(this.userFrom.union_shareholder_expire_at).getTime()
            ) {
              this.userFrom.overdue = 1;
            } else {
              this.userFrom.overdue = 0;
            }
          } else {
            this.userFrom.overdue = 3;
          }

          this.days =
            (new Date(this.userFrom.union_expire_at).getTime() -
              new Date().getTime()) /
            (24 * 60 * 60 * 1000);
          this.days = Math.floor(this.days);
          // this.userFrom.vipUser = 1
        }
      });
    },
    queryString(url) {
      let arr = []; //存储参数的数组
      let res = {}; //存储最终JSON结果对象
      arr = url.split("?")[1].split("&");
      for (let i = 0, len = arr.length; i < len; i++) {
        //如果有等号，则执行赋值操作
        if (arr[i].indexOf("=") != -1) {
          let str = arr[i].split("="); //str=[a,1];
          res[str[0]] = str[1];
        } else {
          //没有等号，则赋予空值
          res[arr[i]] = "";
        }
      }
      res = JSON.stringify(res); //转化为JSON字符串
      return res;
    },
  },
};
</script>
<style scoped>
.van-toast {
  width: 300px;
  min-height: 120px;
}

.van-toast__text {
  font-size: 35px;
}

.van-icon {
  font-size: 40px;
}

.van-dialog {
  /* width: 500px; */
  font-size: 35px !important;
}

.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}

.header {
  width: 100%;
  height: 3.75rem;
  background: #2e2e30;
  border-radius: 0 0 0.3rem 0.3rem;
  position: relative;
}

.header_info {
  width: 726px;
  height: 394px;
  border: 3px solid #f5e9d6;
  background: linear-gradient(89deg, #f2d39d 0%, #dab274 100%);
  opacity: 1;
  border-radius: 34px;
  position: absolute;
  left: 9px;
  top: 100px;
  padding: 36px 45px;
  box-sizing: border-box;
  position: relative;
}

.header_img {
  width: 718px;
  height: 264.39px;
  position: absolute;
  top: 49px;
  left: 0;
}

.header_user {
  position: absolute;
  top: 36px;
  left: 45px;
  display: flex;
  font-size: 35px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #563b15;
  opacity: 1;
  z-index: 2;
}

.info {
  color: #563b15;
  font-size: 25px;
  position: absolute;
  top: 0px;
  right: -300px;
}

.user_img {
  width: 96px;
  height: 96px;
  border: 3px solid #ffffff;
  border-radius: 50%;
  opacity: 1;
}

.lador_icon {
  width: 46px;
  height: 46px;
  margin-top: 13px;
  margin-right: 15px;
}

.user_info {
  margin-left: 20px;
}

.lador_list {
  padding-left: 20px;
}

.list_title {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(46, 46, 48, 0.5);
  opacity: 1;
  margin: 250px 46px 20px 19px;
  display: flex;
  justify-content: space-between;
}

.listLog {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #909090;
  margin-bottom: 30px;
}

.list_img1 {
  border-radius: 50%;
  margin-bottom: 30px;
}

.list_img1 img {
  width: 95px;
  height: 95px;
}

.list_icon1 {
  width: 130px;
  text-align: center;
  margin-top: 20px;
  margin: 10px 20px;
}

.vipImg1 {
  position: relative;
}

.vip_text1 {
  position: absolute;
  left: 48px;
  top: 22px;
  font-size: 18px;
  color: #d4b479;
}

.error_box {
  font-size: 36px;
  color: rgba(86, 59, 21, 1);
  margin-top: 130px;
  position: absolute;
  top: 30px;
  left: 50px;
  width: 90%;
}

.error_icon {
  font-size: 26px;
  color: rgba(255, 57, 30, 1);
  display: flex;
  margin: 20px 0;
}

.error_icon img {
  width: 26px;
  height: 26px;
}

.error_icon1 {
  font-size: 26px;
  color: rgba(255, 57, 30, 1);
  display: flex;
  margin: 100px 0;
}

.error_icon1 img {
  width: 26px;
  height: 26px;
}

.confim_box {
  width: 190px;
  height: 74px;
  background: linear-gradient(180deg, #252324 0%, #3c3633 100%);
  box-shadow: 0px 4px 10px 0px rgba(184, 152, 100, 0.5);
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  font-size: 30px;
  color: rgba(249, 227, 190, 1);
  text-align: center;
  line-height: 74px;
  margin-left: 450px;
  position: absolute;
  z-index: 3;
  bottom: 120px;
  right: 30px;
}

.confim_box1 {
  width: 190px;
  height: 74px;
  background: linear-gradient(180deg, #252324 0%, #3c3633 100%);
  box-shadow: 0px 4px 10px 0px rgba(184, 152, 100, 0.5);
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  font-size: 30px;
  color: rgba(249, 227, 190, 1);
  text-align: center;
  line-height: 74px;
  margin-left: 450px;
  display: flex;
  position: absolute;
  bottom: 100px;
  right: 30px;
}

.confim_box1 img {
  width: 36px;
  height: 36px;
  margin: 15px 10px 0 20px;
}

.van-toast {
  width: 300px;
  min-height: 120px;
}

.van-toast__text {
  font-size: 35px;
}

.van-icon {
  font-size: 40px;
}

.van-dialog {
  /* width: 500px; */
  font-size: 35px !important;
}

.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}

.userFromText {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.phone_icon {
  font-size: 20px;
  color: #000000;
  margin-top: 30px;
}

.phone_icon img {
  width: 28px;
  vertical-align: middle;
}

.status_info {
  display: flex;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #563b15;
  margin-top: 26px;
}

.status_info img {
  width: 26px;
  height: 24px;
  margin-right: 10px;
}

.text_info {
  margin-top: 170px;
  position: relative;
  z-index: 999;
}

.info_right {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #ff0d0d;
  margin-left: 53px;
}

.info_left {
  margin-right: 25px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #ff0d0d;
}
.userBtn {
  width: 190px;
  height: 74px;
  background: linear-gradient(180deg, #252324 0%, #3c3633 100%);
  border-radius: 100px 100px 100px 100px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #f9e3be;
  text-align: center;
  line-height: 74px;
  position: absolute;
  bottom: 141px;
  right: 40px;
}

.cancel_box {
  width: 100%;
  height: 100%;
}

.cancel_box img {
  width: 100%;
  height: 100%;
}

.text_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.message_title {
  margin: 10px 0 50px;
}

.message_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  color: rgba(26, 26, 26, 0.8);
  width: 528px;
  margin: 31px auto 40px;
  text-align: left;
}

.sure_btn {
  width: 180px;
  height: 68px;
  background: linear-gradient(179deg, #ff6f51 0%, #ff0639 100%);
  border-radius: 34px 34px 34px 34px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #ffffff;
  text-align: center;
  line-height: 68px;
  margin: 36px auto 31px;
}

.text1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.6);
  margin-bottom: 24px;
}

.rule_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #1a1a1a;
}
.rule_text span {
  color: #007dff;
}
.text_box img {
  width: 25px;
  height: 25px;
  margin-top: 52px;
  margin-left: 450px;
}
.progress_box {
  padding: 50px 30px;
  box-sizing: border-box;
  position: relative;
  .left_price {
    left: 20px;
    position: absolute;
    top: 3px;
  }
  .right_rate {
    right: 20px;
    top: 3px;
    position: absolute;
  }
}
</style>
